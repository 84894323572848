import React from "react";

class ArrowText extends React.Component {
  state = {
    expand: false,
  };

  render() {
    return (
      <>
        {this.props && this.props.left ? "←" : '→'}
      </>
    );
  }
}

export default ArrowText;
