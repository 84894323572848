/**
 * Basic Pub/Sub protocol
 * 
 * Author: Brandon Hudson
 * Created: 12-15-2019
 */

class PubSub {
    static registry = {}

    /**
     * Publish to a topic
     * 
     * @param name 
     * @param data 
     */
    static publish(name, data = null) {
        if (!this.registry[name]) return;
            this.registry[name].forEach((func) => {
                func.call(null, data);
            });
    }
     
    /**
     * Subscribe to a topic
     * 
     * @param name 
     * @param fn 
     */
    static subscribe(name, fn = null) {
        if (!this.registry[name]){
            this.registry[name] = [fn];
        } else {
            this.registry[name].push(fn);
        }
    }
}

export default PubSub;
