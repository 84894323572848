/**
 * Event constants for the app.
 */

class Event {
    static ENV_CHANGED = 'apiEnvChanged';
    static USER_LOADED = 'userLoaded';

    static BILLING_ACCOUNT = {
        DELETED: 'billingAccountDeleted',
        CREATED: 'billingAccountCreated',
        MODIFIED: 'billingAccountModified',
        CREATE_OPEN: 'openCreateBillingAccountModal',
    };

    static MEAL = {
        DELETED: 'mealDeleted',
        CREATED: 'mealCreated',
        MODIFIED: 'mealModified',
        CREATE_OPEN: 'openMealCreateModal',
    };

    static STORE = {
        DELETED: 'storeDeleted',
        CREATED: 'storeCreated',
        MODIFIED: 'storeModified',
        CREATE_OPEN: 'openCreateStoreModal',
        MODIFY_OPEN: 'openModifyStoreModal',
        DELETE_OPEN: 'openDeleteStoreModal',
        MODIFY_BILLING_ACCOUNT_OPEN: 'openModifyStoreBillingAccountModal',
    };

    static PLAN = {
        CREATE_OPEN: 'openCreatePlanModal',
    };

    static PRODUCT_GROUP = {
        DELETED: 'productGroupDeleted',
        CREATED: 'productGroupCreated',
        MODIFIED: 'productGroupModified',
        CREATE_OPEN: 'openProductGroupModal',
    };
 }

 export default Event;
