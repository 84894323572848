import PubSub from "./PubSub";
import Event from "./Event";
import axios from "axios";

export default class API {
  static ENV_STAGING = "https://apiv2-staging.projectleannation.com";
  static ENV_PRODUCTION = "https://apiv2.projectleannation.com";
  static ENV_DEV = "http://localhost:8080";

  /**
   * Sets the environment
   *
   * @param {*} env
   */
  static setEnvironment(env) {
    this.ENV_CURRENT = env;

    PubSub.publish(Event.ENV_CHANGED);
  }

  /**
   * Gets the current auth token.
   */
  static getAuthToken() {
    return this.ENV_USER_TOKEN;
  }

  /**
   * Sets the user's auth token.
   *
   * @param {*} val
   */
  static setAuthToken(val) {
    this.ENV_USER_TOKEN = val;
  }

  /**
   * Sends a generic request.
   *
   * @param {*} options
   */
  static _sendRequest(options, force = true) {
    if (this.ENV_CURRENT || force) {
      return axios(options);
    } else {
      return new Promise((resolve, reject) => {
        PubSub.subscribe(Event.ENV_CHANGED, () => {
          options.uri = this.ENV_CURRENT + options.uri;

          axios(options).then(
            (data) => {
              if (data) {
                resolve(data);
              }
            },
            (error) => {
              reject(error);
            }
          );
        });
      });
    }
  }

  /**
   * Sends an unauthenticed HTTP request.
   *
   * @param method
   * @param path
   * @param data
   */
  static _unauthenticatedRequest(method, path, data = null) {
    let options = {
      method: method,
      url: this.ENV_CURRENT + path,
      data: null,
    };

    if (data) {
      options.data = data;
    }

    return this._sendRequest(options);
  }

  /**
   * Sends a basic authenticated HTTP request.
   *
   * @param method
   * @param path
   * @param username
   * @param password
   * @param data
   */
  static _basicAuthRequest(method, path, username, password, data = null) {
    let options = {
      method: method,
      url: this.ENV_CURRENT + path,
      data: null,
      auth: {
        user: username,
        pass: password,
      },
    };

    if (data) {
      options.data = data;
    }

    return this._sendRequest(options);
  }

  /**
   * Sends a bearer authenticated HTTP request.
   *
   * @param method
   * @param path
   * @param token
   * @param data
   */
  static _bearerAuthRequest(method, path, token, data = null) {
    let options = {
      method: method,
      url: this.ENV_CURRENT + path,
      data: null,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data) {
      options.data = data;
    }

    return this._sendRequest(options);
  }

  /**
   * Authenticates the user.
   *
   * @param email
   * @param password
   */
  static async authenticate(email = "", password = "") {
    return this._basicAuthRequest(
      "GET",
      "/account/authenticate",
      email,
      password
    );
  }

  static async signup(
    email,
    zip,
    source,
    name = "",
    phone = "",
    force_owner_overwrite = false,
    utmSource = "",
    utmMedium = "",
    utmCampaign = "",
    utmTerm = "",
    utmContent = "",
    promo = "",
    storeID = ""
  ) {
    let params = {
      email: email,
      source: source,
    };

    if (utmSource) {
      params.utmSource = utmSource;
    }

    if (utmMedium) {
      params.utmMedium = utmMedium;
    }

    if (utmCampaign) {
      params.utmCampaign = utmCampaign;
    }

    if (utmTerm) {
      params.utmTerm = utmTerm;
    }

    if (utmContent) {
      params.utmContent = utmContent;
    }

    if (zip) {
      params.zipCode = zip;
    }

    if (force_owner_overwrite) {
      params.forceOverwrite = force_owner_overwrite;
    }

    if (name) {
      params.name = name;
    }

    if (phone) {
      params.phone = phone;
    }

    if (promo) {
      params.couponCode = promo;
    }

    if (storeID) {
      params.storeID = storeID;
    }

    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/lead",
      params
    );
  }

  static async signupForNewsletter(email) {
    let params = {
      email: email,
    };

    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/newsletter",
      params
    );
  }

  static async createAffiliate(
    store = "",
    name = "",
    companyName = "",
    email = "",
    phone = "",
    zipCode = "",
    members = "",
    notes = "",
    type = ""
  ) {
    let params = {
      storeID: store,
      email: email,
      name: name,
      companyName: companyName,
      phone,
      zipCode,
      numberMembers: members,
      notes,
      type,
    };

    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/affiliate",
      params
    );
  }

  static async getLocationFromIP() {
    let options = {
      method: "GET",
      url: "http://ip-api.com/json",
      data: null,
    };

    return this._sendRequest(options);
  }

  static async getStores() {
    return this._unauthenticatedRequest("GET", "/public/stores", null);
  }

  static async getStoresForZip(zip, withAll = false) {
    return this._unauthenticatedRequest(
      "GET",
      "/public/stores?zip=" + zip + "&withAll=" + withAll,
      null
    );
  }
}
