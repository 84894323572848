/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ArrowText from "../ArrowText";
import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

let logo = require("../../assets/img/bailey/Gribben.svg");

class PrimaryNavbar extends React.Component {
  collapse() {
    let collapse = document.getElementById("navbar-collapse-main");

    if (collapse) {
      collapse.click();
    }
  }

  render() {
    return (
      <>
        <Navbar
          className="navbar-top fixed-top navbar-horizontal navbar-light bg-white start"
          expand=""
          style={{ width: "100%", maxWidth: 1000, margin: "auto" }}
        >
          <Container fluid className="px-0 px-sm-0 px-md-4">
            <button
              className="navbar-toggler pr-0 pl-0"
              id="navbar-collapse-main"
              aria-label="menu toggle button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <Link
              className="nav-link py-2 px-lg-6"
              to="#about"
              activeClass=""
              style={{
                position: "fixed",
                left: "50%",
                top: "9px",
                transform: "translateX(-50%)",
              }}
              aria-label="Home"
              onClick={this.collapse.bind(this)}
              offset={-70}
              duration={300}
            >
              <img
                src={logo}
                alt="Gribben logo"
                width="120"
                height="30"
                style={{ width: "120px" }}
                className="bailey-logo"
              ></img>
            </Link>

            <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
              <div className="navbar-collapse-header d-md-none">
                <Row className="align-items-end">
                  <Col className="collapse-close" xs="12">
                    <button
                      className="navbar-toggler"
                      id="navbar-collapse-main-2"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav navbar>
                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="about"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      About Me
                    </span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="brands"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Brands &amp; Companies
                    </span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="portfolio"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Portfolio
                    </span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="education"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Education &amp; Experience
                    </span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="certifications"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Certifications
                    </span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="contact"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Contact me
                    </span>
                  </Link>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default PrimaryNavbar;
